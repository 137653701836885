<template>
  <div v-if="getBalance[currency] && getCurrency[currency]">
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-gray-300"
      >
        <div class="max-w-2xl w-full p-7">
          <div>
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>
        </div>
      </div>

      <div
        class="mx-auto max-w-xl flex-col items-center justify-start flex-grow w-full p-3"
      >
        <div
          class="inline-block w-full mt-1 p-px bg-gradient-to-r from-blue-800 via-gray-500 to-green-700 rounded"
        >
          <div
            class="inline-block w-full text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded bg-gray-900 bg-opacity-75"
          >
            <div class="flex flex-col sm:flex-row px-8 py-3 sm:py-6">
              <div class="flex flex-grow items-center py-3 sm:py-0">
                <!-- <img class="h-8 mr-2" :src="getCurrency[currency].logo" /> -->

                <span
                  class="text-2xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-pink-400"
                >
                  Available Credit: {{ getUser.credits_recovery }}
                </span>
              </div>

              <!-- <div class="flex-none sm:mt-2">
                <div
                  class="inline text-lg px-2 py-1 mr-2 border rounded-full text-yellow-50 border-yellow-50 cursor-pointer opacity-80 hover:opacity-100 focus:opacity-100"
                >
                  <a
                    class="opacity-80 hover:opacity-100 focus:opacity-100 text-white"
                    target="_blank"
                  >
                    <i class="fa fa-globe"></i>
                    {{ getUser.credits_recovery }}
                  </a>
                </div>
              </div> -->
            </div>

            <div class="px-8 py-3 sm:py-6">
              <div class="text-gray-200">
                <div class="grid grid-cols-1 gap-y-3">
                  <div class="flex flex-col space-y-8 space-y-0">
                    <div>
                      <div class="flex font-semibold mt-2">
                        <span
                          class="flex-grow leading-tight text-gray-500 sm:text-base"
                          style="font-size: 12px"
                        >
                          Available USDT
                        </span>
                        <span
                          class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base"
                          ><span style="font-size: 14px">
                            {{ getBalance[currency].available }}
                            {{ currency }}</span
                          ></span
                        >
                      </div>

                      <div class="mt-5 text-gray-300">
                        <div class="flex items-center mb-4">
                          <input
                            @change="changeCredits10(credits_10)"
                            v-model="credits_10"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="default-checkbox"
                            class="ml-2 text-sm font-medium"
                            >10 credits / 5 USDT</label
                          >
                        </div>
                        <div class="flex items-center mb-4">
                          <input
                            @change="changeCredits20(credits_20)"
                            v-model="credits_20"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="default-checkbox"
                            class="ml-2 text-sm font-medium"
                            >20 credits / 10 USDT</label
                          >
                        </div>
                        <div class="flex items-center">
                          <input
                            @change="changeCredits50(credits_50)"
                            v-model="credits_50"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            for="default-checkbox"
                            class="ml-2 text-sm font-medium"
                            >50 credits / 20 USDT</label
                          >
                        </div>
                      </div>

                      <!-- <div class="mt-10">
                        <div
                          class="flex justify-center py-1 sm:py-3 px-3 rounded-xl bg-gray-700"
                        >
                          <input
                            id="amount"
                            v-model="withdrawal_amount"
                            placeholder="0"
                            class="flex-grow outline-none text-sm bg-transparent"
                            type="number"
                            min="0"
                            step="0.00000001"
                          /><span
                            class="w-20 flex-none text-sm px-6 mt-1 rounded text-gray-400 cursor-pointer hover:text-yellow-200"
                          >
                            MAX
                          </span>
                        </div>
                      </div> -->
                      <div class="flex justify-center py-3 mt-10">
                        <button
                          @click="buyCredits()"
                          class="w-full flex-none text-sm px-6 py-2 border rounded-xl text-yellow-50 border-none bg-gradient-to-r from-blue-700 to-green-600"
                        >
                          Buy Credits
                        </button>
                      </div>

                      <span
                        class="grid grid-cols-1 lg:grid-cols-2 sm:flex-row p-2 border rounded-lg mb-2 border-opacity-30 text-xs sm:text-base"
                      >
                        <span>
                          <img
                            class="inline-block h-5 mr-2"
                            :src="require(`@/assets/chain/${chain}.png`)"
                          />
                          Binance Smart Chain</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import func from "@/widgets/func";
import user from "@/api/user";
import LiveTx from "@/components/LiveTx.vue";
import {
  POOL_ADDRESS,
  SITE_URL,
  CHAIN,
  EXPLORER,
  CHAIN_FULL_NAME,
} from "../../config";
import nativeToast from "native-toast";

import ConnectWallet from "@/components/ConnectWallet.vue";

export default {
  components: {
    ConnectWallet,
    LiveTx,
  },
  data() {
    return {
      chain: CHAIN.toLowerCase(),
      explorer: EXPLORER,
      chain_full: CHAIN_FULL_NAME,
      currency: "USDT",
      accounts: [],
      deposit_amount: 0,
      withdrawal_amount: 0,
      loading: false,
      value: null,
      dara: null,
      to: null,
      contract_address: null,
      tokenBalance: null,
      lasttx: null,
      credits_10: true,
      credits_20: false,
      credits_50: false,
    };
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),
  methods: {
    ...mapActions(["setUser"]),

    async getAccount() {
      this.accounts = await ethereum.request({ method: "eth_requestAccounts" });
      this.getTokenBalance();
    },

    amount(a) {
      return func.fixed8(a);
    },
    changeCredits10() {
      if (this.credits_10) {
        this.credits_20 = false;
        this.credits_50 = false;
      }
    },
    changeCredits20() {
      if (this.credits_20) {
        this.credits_10 = false;
        this.credits_50 = false;
      }
    },
    changeCredits50() {
      if (this.credits_50) {
        this.credits_10 = false;
        this.credits_20 = false;
      }
    },

    async buyCredits() {
      var credits = "10";

      if (this.credits_10) {
        credits = 10;
      } else if (this.credits_20) {
        credits = 20;
      } else {
        credits = 50;
      }

      user
        .buyCredits({
          user_id: this.getUser._id,
          credits,
        })
        .then((response) => {
          this.setUser(response.data);

          nativeToast({
            message: "Buy success",
            position: "top",
            timeout: 10000,
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {},
  updated() {},
};
</script>
